
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { inject } from '@/inversify';
import PAGES from '@/modules/common/constants/pages.constant';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '@/modules/cluster/cluster-deep-analysis.service';
import DeepCompsetAnalysisCommonService, { DeepCompsetAnalysisCommonServiceS } from '@/modules/deep-analysis/deep-analysis-common.service';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import HotelStatisticItem from '@/modules/deep-analysis/interfaces/hotel-statistic-item';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import VIEW_TYPE from '@/modules/deep-analysis/constants/view-type-filter.constant';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import VALUE_TYPE from '@/modules/deep-analysis/constants/value-type.constant';
import CompsetModel from '@/modules/compsets/models/compset.model';
import HotelIndicator from './deep-analysis-hotel-indicator.vue';
import ClusterDeepAnalysisModel from '../../../models/cluster-deep-analysis.model';
import ClusterService, { ClusterServiceS } from '../../../cluster.service';

@Component({ components: { HotelIndicator } })
export default class HotelLine extends Vue {
    @inject(ClusterServiceS) private clusterService!: ClusterService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(DeepCompsetAnalysisClusterServiceS) private dcaClusterService!: DeepCompsetAnalysisClusterService;
    @inject(DeepCompsetAnalysisCommonServiceS) private dcaCommonService!: DeepCompsetAnalysisCommonService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    @Prop({ type: Object })
    hotelData!: ClusterDeepAnalysisModel;

    isDocumentLoading = false;

    get isLoading() {
        return this.isDocumentLoading || !this.hotelData.documentLoaded;
    }

    get statistics() {
        return this.dcaClusterService.statistics;
    }

    // Lazy load of document, send request only if component is in viewport.
    loadViewportedHotel(isVisible: boolean) {
        if (this.isDocumentLoading || !isVisible || this.hotelData.documentLoaded) {
            return;
        }

        this.isDocumentLoading = true;
        this.clusterService
            .loadDaysData(PAGES.DEEP_ANALYSIS, this.hotelData.hotelId)
            .finally(() => {
                this.isDocumentLoading = false;
            });
    }

    async goToHotel(hotelId: number) {
        // Need to pass empty object, as DA page doesn't have compsets, compset parameter must not be empty to keep filter from cluster page though.
        this.userViewService.goToHotelPage(hotelId, {} as CompsetModel);
    }

    get mappedStats() {
        const hotelStats = {} as Record<STATISTIC_TYPE, HotelStatisticItem>;

        const {
            provider, showBy, viewType, compareTo,
        } = this.dcaClusterService;

        const providerData = this.hotelData.compsetMain ? this.hotelData.compsetMain[provider] : null;

        this.statistics.forEach((statistic: STATISTIC_TYPE) => {
            const [type, value] = showBy.split('.');
            hotelStats[statistic] = {} as HotelStatisticItem;
            const statistics = providerData?.showBy[type]![value];

            if (!statistics) {
                return;
            }

            let diffProp: number | null = null;
            let comparedValueProp: number | null = null;

            hotelStats[statistic].valueType = this.dcaCommonService.getValueType(statistic);
            hotelStats[statistic].currency = this.hotelData.compsetMain[provider]?.currency!;
            hotelStats[statistic].brandTrend = this.dcaClusterService.getTrend(statistic, statistics.performanceMyHotel[statistic]);

            if (viewType === VIEW_TYPE.ABSOLUTE) {
                const {
                    myHotel,
                    absoluteCompSet,
                    absoluteLastYear,
                    compset,
                    lastYear,
                } = statistics;

                hotelStats[statistic].brand = this.dcaClusterService.getFormattedValue(myHotel[statistic], statistic);

                let diffProp: number | null = null;
                let comparedValueProp: number | null = null;

                switch (compareTo) {
                    case COMPARE_TO.COMPSET_AVG:
                        diffProp = absoluteCompSet[statistic];
                        comparedValueProp = compset[statistic];
                        break;

                    case COMPARE_TO.LAST_YEAR:
                        diffProp = absoluteLastYear[statistic];
                        comparedValueProp = lastYear[statistic];
                        break;

                    default: break;
                }

                hotelStats[statistic].diff = this.dcaClusterService.getAbsoluteValue(diffProp);
                hotelStats[statistic].diffTrend = this.dcaClusterService.getTrend(statistic, diffProp);
                hotelStats[statistic].comparedValue = this.dcaClusterService.getFormattedValue(comparedValueProp, statistic);
            } else {
                const {
                    performanceMyHotel,
                    performanceCalculatedCompSet,
                    performanceCompSet,
                    performanceCalculatedLastYear,
                    performanceLastYear,
                } = statistics;

                hotelStats[statistic].brand = this.dcaClusterService.getAbsoluteValue(performanceMyHotel[statistic]);
                hotelStats[statistic].valueType = VALUE_TYPE.PERCENT;

                switch (compareTo) {
                    case COMPARE_TO.COMPSET_AVG:
                        diffProp = performanceCalculatedCompSet[statistic];
                        comparedValueProp = performanceCompSet[statistic];
                        break;

                    case COMPARE_TO.LAST_YEAR:
                        diffProp = performanceCalculatedLastYear[statistic];
                        comparedValueProp = performanceLastYear[statistic];
                        break;

                    default: break;
                }

                hotelStats[statistic].diff = this.dcaClusterService.getAbsoluteValue(diffProp);
                hotelStats[statistic].diffTrend = this.dcaClusterService.getTrend(statistic, diffProp);
                hotelStats[statistic].comparedValue = this.dcaClusterService.getAbsoluteValue(comparedValueProp);
            }
        });

        return hotelStats;
    }
}
