var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        'total-indicator': true,
        'total-indicator--skeleton': _vm.skeleton,
    }},[(_vm.statData && _vm.statData.decrease)?_c('button',{class:[
            'total-indicator__proportion',
            _vm.isDecreaseActive && 'total-indicator__proportion--active',
            'total-indicator__proportion--decrease'
        ],style:({ width:  `${_vm.decreaseWidth}%`}),on:{"click":function($event){return _vm.setStatisticFilter('low')}}}):_vm._e(),(_vm.statData && _vm.statData.noChange)?_c('button',{class:[
            'total-indicator__proportion',
            'total-indicator__proportion--no-change',
        ],on:{"click":function($event){return _vm.setStatisticFilter('no-change')}}}):_vm._e(),(_vm.statData && _vm.statData.increase)?_c('button',{class:[
            'total-indicator__proportion',
            _vm.isIncreaseActive && 'total-indicator__proportion--active',
            'total-indicator__proportion--increase'
        ],style:({ width:  `${_vm.increaseWidth}%`}),on:{"click":function($event){return _vm.setStatisticFilter('high')}}}):_vm._e(),(_vm.statData && !_vm.statData.total)?_c('button',{class:[
            'total-indicator__proportion',
            'total-indicator__proportion--no-statData',
            _vm.skeleton && 'skeleton'
        ],on:{"click":function($event){return _vm.setStatisticFilter(null)}}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }