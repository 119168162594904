import { Component, Mixins } from 'vue-property-decorator';
import ClusterOtelMixin from '../common/cluster-otel.mixin';

/**
 * Mixin for benchmark cluster pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class BenchmarkClusterOtel extends Mixins(ClusterOtelMixin) {
    get payload() {
        const filterToBuild = ['month', 'year']; // Add benchmark specific filters
        const settingsUnion = {
            ...this.documentFiltersService.settings,
        } as Record<string, any>;

        const filterBy = filterToBuild.reduce((acc, key) => {
            acc[key] = settingsUnion[key];
            return acc;
        }, {} as any);

        return {
            'cx.action.filterBy': this.otelService.buildFilterBy(filterBy),
        };
    }
}
