

import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ClusterTooltip from './da-cluster-tooltip.vue';
import HotelSearchbar from '../_common/hotel-searchbar.vue';
// import CurrencySwitcher from '@/modules/common/components/currency-switcher.vue';

@Component({
    components: {
        DateDocumentFilter,
        ClusterTooltip,
        HotelSearchbar,
        // CurrencySwitcher,
    },
})
export default class DeepAnalysisHeader extends Vue {
    @inject(UserServiceS) private userService!: UserService;

    infoTooltip: boolean = false;

    get name() {
        const { user } = this.userService;
        return user
            ? user.chainName
            : null;
    }
}
