

import {
    Prop, Vue, Component,
} from 'vue-property-decorator';

@Component
export default class SortingButtons extends Vue {
    @Prop({
        required: false,
        default: null,
        type: String,
    })
    current!: string | null;

    @Prop({
        required: true,
        type: String,
    })
    name!: string;

    @Prop({
        required: true,
        type: Number,
    })
    sortState!: -1 | 1 | null;

    get sortingActive() {
        return this.current === this.name
            && this.sortState !== null;
    }

    handleSorting(sortState: -1 | 1) {
        this.$emit('sort', {
            key: this.name,
            type: sortState,
        });
    }
}
