

import { Component, Vue } from 'vue-property-decorator';
import ExcelBtn from '@/modules/deep-analysis/components/deep-analysis-excel.vue';
import ProviderFilter from './actions/provider-filter.vue';
import CompareToFilter from './actions/compare-to-filter.vue';
import ShowByFilter from './actions/show-by-filter.vue';
import ViewFilter from './actions/view-filter.vue';

@Component({
    components: {
        ProviderFilter,
        CompareToFilter,
        ShowByFilter,
        ViewFilter,
        ExcelBtn,
    },
})
export default class DeepAnalysisActions extends Vue {}
