
import { Component, Vue, Prop } from 'vue-property-decorator';

/** Placeholder to render pseudo text in loading state */
@Component
export default class SkeletonLine extends Vue {
    @Prop({ type: Boolean, default: false })
    isGray!: boolean;

    @Prop({ type: Number, default: 5 })
    wordsNum!: number;

    private wordLengths = [12, 18, 12, 9, 12, 15];

    word(i: number) {
        let index = i;
        while (index >= this.wordLengths.length) {
            index -= this.wordLengths.length;
        }
        return new Array(this.wordLengths[index]).fill('_').join('');
    }
}
