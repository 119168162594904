

import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '../../../cluster-deep-analysis.service';

@Component({
    components: { CustomSelect },
})
export default class CompareToFilter extends Vue {
    @inject(DeepCompsetAnalysisClusterServiceS) deepCompsetAnalysisClusterService!: DeepCompsetAnalysisClusterService;

    get value() {
        return this.deepCompsetAnalysisClusterService.compareTo;
    }

    set value(value: COMPARE_TO) {
        this.deepCompsetAnalysisClusterService.compareTo = value;
    }

    get items(): Item[] {
        return [{
            value: COMPARE_TO.COMPSET_AVG,
            name: this.$tc('filters.deepcomp.avg'),
            disabled: false,

        }, {
            value: COMPARE_TO.LAST_YEAR,
            name: this.$tc('filters.deepcomp.last'),
            disabled: false,
        }];
    }
}
