var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sorting"},[_c('div',{class:{
            'sorting__btn sorting__btn--asc': true,
            'sorting__btn--active': _vm.sortingActive && _vm.sortState === 1,
        },on:{"click":function($event){return _vm.handleSorting(1)}}}),_c('div',{class:{
           'sorting__btn sorting__btn--desc': true,
           'sorting__btn--active': _vm.sortingActive && _vm.sortState === -1,
        },on:{"click":function($event){return _vm.handleSorting(-1)}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }